





















































import { Component, Mixins } from 'vue-property-decorator';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import userService from '../services/user.service';
import ResetPasswordDto from '../dto/reset-password.dto';
import ResetPasswordForm from '../components/reset-password-form.vue';

@Component({
  components: {
    ResetPasswordForm,
  },
  metaInfo(this: ResetPassword) {
    return { title: this.$t('pageTitle.resetPassword').toString() };
  },
})
export default class ResetPassword extends Mixins(HandlesErrorMixin) {
  private loading = false;
  private showSuccessIcon = false;
  private steps = {
    resetPassword: 0,
    passwordReset: 1,
  };
  private step = this.steps.resetPassword;

  passwordReset() {
    setTimeout(() => {
      this.showSuccessIcon = true;
    }, 300);

    this.step = this.steps.passwordReset;
  }

  async onSubmit(data: ResetPasswordDto) {
    this.loading = true;
    const { accessToken } = this.$route.params;

    try {
      await userService.resetPassword(accessToken, data);
      this.passwordReset();
    } catch (error) {
      if (this.isForbiddenError(error)) {
        this.$notify.error({
          title: this.$t('error.invalidResetPasswordLink.title').toString(),
          message: this.$t('error.invalidResetPasswordLink.message').toString(),
          options: { lefty: true },
        });
      } else {
        this.handleError(error);
      }
    } finally {
      this.loading = false;
    }
  }
}
