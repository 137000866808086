


































import { Mixins, Component } from 'vue-property-decorator';
import FormMixin from '@/app/core/mixins/form.mixin';
import { atLeast, minLength, required } from '@/app/core/validation';
import ResetPasswordDto from '../dto/reset-password.dto';

@Component
export default class ResetPasswordForm extends Mixins(FormMixin) {
  private showPassword = false;

  protected data: ResetPasswordDto = {
    password: '',
  };

  protected rules = {
    password: [
      required,
      minLength(8),
      atLeast(1).digits,
      atLeast(1).lowercaseLetters,
      atLeast(1).uppercaseLetters,
    ],
  };
}
